<!--  -->
<template>
    <div class='page-task-action'>
        <exercise :sgf="sgf" 
        @gameover="gameover" 
        @nextExercise="exerciseHand"
        :awardCount.sync="awardCount"
        ref="exercise">
            <span slot="title-text" 
            class="text-normal max-width">第{{currentExNum}}/{{exerciseCount}}题</span>

            <div class="ex-hand-fun" slot="mask-extend" v-if="!nextExerciseData">
                <!-- <dia-button type="confirm" shape="square" class="flex-center" @click="exerciseHand">
                    <span class="text-normal-a">{{pageText.t1}}</span>
                </dia-button> -->
            </div>
        </exercise>
        <div class="overlay-box flex-center" v-if="gameFinishSwitch">
            <div class="icon"></div>
        </div>
        <chapterResult :chapterId.sync="chapterId" ref="result" @restart="restart"></chapterResult>
        <!-- <shareCert @gameOut="gameOut" ref="shareCert"></shareCert> -->
        <cert-window ref="shareCert" @gameOut="gameOut"></cert-window>
    </div>
</template>

<script>
    import exercise from '@/components/exercise/exercise.vue'
    import apiClass from '@/API/page/task'
    import apiCert from '@/API/page/certificate'
    import chapterResult from './resultDia.vue'
    import timeCount from '@/components/exercise/timeCount.vue'

    // import shareCert from '@/components/shareCert.vue'

    export default {
        name: '',
        extends: timeCount,
        props: {},
        data() {
            return {
                api: new apiClass(),
                apiCert: new apiCert(),
                routeData: this.$route.params.routeData,
                exerciseList: [],
                _exercise: null,
                chapterData: {},
                gameFinishSwitch: false,
                exerciseAwardInfo: {},
                certList: [],
                exerciseGroup: this.ENUMS.COURSE_EXERCISE,
            };
        },
        methods: {
            dataInit(){
                this.exerciseList = []
                this.chapterData = {}
                this.gameFinishSwitch = false
                this.timeCountInit()
            },
            async dataLoad(){
                await this.chapterInfoInit()
                await this.exerciseListInit()
                this.exerciseValCheck()
            },
            async chapterInfoInit(){
                let {chapterId: chapter_id, usercode: user_code, exerciseGroup: exercise_group} = this
                let res = await this.api.chapterExStatus({chapter_id, user_code, exercise_group}) 
                if(res) this.chapterData = res
            },
            exerciseComInit(){
                let ele = this.$refs['exercise']
                if(!ele) return
                this._exercise = ele
            },
            async exerciseListInit(){
                let {chapterId: chapter_id, usercode: user_code, exerciseGroup: exercise_group} = this
                let res = await this.api.exerciseList({chapter_id, user_code, exercise_group}) 
                if(res){
                    this.exerciseList = res
                }
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('taskActionBackFun', e => {
                    this.back()
                })
            },
            eventOff(){
                this._common.eventRemove('taskActionBackFun')
            },
            back(){
                let hash = `item_${this.chapterId}`
                this._routeHand.back({hash})
            },
            refreshPage(opt){
                opt = opt || {}
                let {items, name, type} = opt
                type = type || 'replace'
                return this._common.refreshPage.call(this, {items, name, type})
            },
            setParams(items){
                return this._common.setParams.call(this, items)
            },
            refreshPageAction(opt){
                opt = opt || {}
                let {name, params, type} = opt
                name = name || 'task-action'
                type = type || 'replace'
                return this._common.refreshPageAction.call(this, {name, params, type})
            },
            async gameover(opt){
                this.timeCountClear()
                let res = await this.answerUpload(opt)
                if(!res) return
                this.exerciseAwardInfo = {
                    exerciseId: opt.exerciseId,
                    awardCount: res.awardCount
                }
                let {
                    nextExerciseDataCul: {id} = {}
                } = this
                // if(id) this._exercise.resultMaskOpen()
                // else this.gameCount()
                this._exercise.resultMaskOpen()
                // if(id) this._exercise.resultMaskOpen()
                if(!id) this.gameCount()
            },
            async answerUpload(opt){
                let {actual: userAnswer, correct, isCorrect} = opt
                correct = correct == userAnswer ? 1 : 0
                if(isCorrect !== undefined) correct = isCorrect ? 1 : 0
                let {exerciseId, usercode: user_code} = this
                let {time: answerDuration} = this
                let res = await this.api.resultUpload({userAnswer, exerciseId, correct, user_code, answerDuration})

                return res
            },
            
            async gameCount(){
                let {usercode: user_code} = this
                await this._common.settimeout(1000)
                this.gameFinish()
                await this._common.settimeout(1000)
                this.closeGameFinish()
                let res = await this.apiCert.addCertificate({user_code})
                let {certificateList:list} = res || []
                if(!list.length) return this.gameCountAction()
                this.$refs['shareCert'].open(list)
            },
            gameOut(){
                this.gameCountAction()
            },
            gameFinish(){
                this.gameFinishSwitch = true
            },
            closeGameFinish(){
                this.gameFinishSwitch = false
            },
            gameCountAction(){
                // let {chapterId} = this
                // if(!chapterId) return
                // let name = 'task-result', type = 'replace', params = {chapterId}
                // this._routeHand.goto({name, type, params})
                this.$refs['result'].open()
            },
            exerciseValCheck(){
                let {id: exerciseId} = this.currentExercise || {}
                let {defaultExerciseId: defaultId} = this
                if(exerciseId || !defaultId) return
                let items = {exerciseId: defaultId}
                this.refreshPage({items})
            },
            exerciseHand(){
                let {
                    nextExerciseDataCul: {id: exerciseId} = {}
                } = this
                this.exerciseAwardInfo = {}
                return exerciseId ? this.nextExercise() : this.gameCount()
            },
            async nextExercise(){
                let {
                    nextExerciseDataCul: {id: exerciseId} = {}
                } = this
                if(!exerciseId) return
                let items = {exerciseId: undefined}
                await this.refreshPage({items})
                items = {exerciseId}
                await this.refreshPage({items})
            },
            async restart(){
                let data = this.routeDataCul
                let {chapterId} = this
                let items = {}
                for (const i in data) {
                    items[i] = undefined
                }
                items.chapterId = chapterId
                this.dataInit()
                await this.refreshPage({items})
                this.dataLoad()
            }
        },
        created() {
            this.eventSet()
        },
        components: {
            exercise,
            chapterResult,
            // shareCert,
        },
        computed: {
            awardCount(){
                return this.exerciseAwardInfo.awardCount || 0
            },
            chapterInfo(){
                let {chapterData: {townCourseChapter = {}} = {}} = this
                return townCourseChapter
            },
            nextExerciseDataCul(){
                let data = this.nextExerciseData
                return data || {}
            },
            nextExerciseData(){
                let {exerciseListCul: list, currentExercise: curEx = {}} = this
                let rd = null
                for(let i in list){
                    if(list[i].id == curEx.id){
                        let tem = list[Number(i) + 1]
                        rd = tem
                    }
                }
                return rd
            },
            defaultExerciseId(){
                let {id: exerciseId} = this.exerciseListCul[0] || {}
                return exerciseId
            },
            routeDataCul(){
                let {routeData} = this
                return this._common.routeDataCul(routeData)
            },
            chapterId(){
                let {chapterId} = this.routeDataCul
                return chapterId
            },
            sgf(){
                let {
                    currentExercise: {exerciseContent: sgf} = {}
                } = this
                return sgf
            },
            exerciseId(){
                let {exerciseId} = this.routeDataCul
                return exerciseId
            },
            usercode(){
                return this._userHand.usercodeGet()
            },
            exerciseCount(){
                return this.exerciseListCul.length || 0
            },
            currentExNum(){
                let {exNum = 0} = this.currentExercise || {}
                return exNum
            },
            exerciseListCul(){
                let {exerciseList: list} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    list[i].exNum = Number(i) + 1
                }
                return list
            },
            currentExercise(){
                let {exerciseListCul: list, exerciseId} = this
                let rd = {}
                for(let i in list){
                    let {id} = list[i] || {}
                    if(id == exerciseId){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
        },
        watch: {
            exerciseId: {
                handler(val, oldVal){
                    if(!val || val == oldVal) return
                    this.timeCountStart()
                },
                deep: true,
                immediate: true
            },
            chapterId: {
                handler(val, oldval){
                    if(!val || val == oldval) return
                    this.dataLoad()
                },
                deep: true,
                immediate: true
            },
            $route: {
                handler(){
                    this.routeData = this.$route.params.routeData
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
            this.exerciseComInit()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' scoped>
    .page-task-action{
        .overlay-box{
            position: fixed;
            top: 0px;
            left: 0px;
            // width: @designWidth;
            width: 100vw;
            height: 100vh;
            // height: @designHeight;
            background: rgba(0, 0, 0, 0.65);
            z-index: 301;
            .icon{
                width: 372px;
                height: 120px;
                background: url('@{assetsUrl}/img/task/t1.png');
                background-size: 100% 100%;
            }
        }
    }
</style>